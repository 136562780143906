<template>
    <div class="chatbot-window">
      <!-- Close Button -->
      <button class="close-button" @click="$emit('close')">✖</button>
  
      <!-- Greeting Message -->
      <div class="welcome-message">
        <p v-if="userProfile">
          Hi, {{ userProfile.firstName }} {{ userProfile.lastName }}! How can I assist you today?
        </p>
        <p v-else>Loading...</p>
      </div>
  
      <!-- Tab Content Area -->
      <div class="tab-content">
        <div v-if="selectedTab === 'FAQ'" class="tab-panel">
          <p>Here are some frequently asked questions:</p>
          <!-- Add FAQ content here -->
        </div>
        <div v-if="selectedTab === 'HELP'" class="tab-panel">
          <p>Here is the help content:</p>
          <!-- Add HELP content here -->
        </div>
        <div v-if="selectedTab === 'ASK MATTHEW'" class="tab-panel">
          <!-- Main chatbot message area -->
          <div class="messages">
            <div v-for="(msg, index) in messages" :key="index" class="message">
              <span :class="msg.sender">{{ msg.text }}</span>
            </div>
          </div>
          <!-- Input Area -->
          <div class="input-area">
            <input v-model="userMessage" @keyup.enter="sendMessage" placeholder="Type your message..." />
            <button @click="sendMessage">Send</button>
          </div>
        </div>
      </div>
  
      <!-- Tabs -->
      <div class="tabs">
        <button :class="{ active: selectedTab === 'FAQ' }" @click="selectedTab = 'FAQ'">FAQ</button>
        <button :class="{ active: selectedTab === 'HELP' }" @click="selectedTab = 'HELP'">HELP</button>
        <button :class="{ active: selectedTab === 'ASK MATTHEW' }" @click="selectedTab = 'ASK MATTHEW'">ASK MATTHEW</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    name: 'ChatbotWindow',
    props: {
      userProfile: {
        type: Object,
        required: true
      }
    },
    setup() {
      const userMessage = ref('');
      const messages = ref([
        { sender: 'bot', text: 'ASK MATTHEW' }
      ]);
      const selectedTab = ref('ASK MATTHEW'); // Default tab
  
      // Handle sending a message
      const sendMessage = () => {
        if (userMessage.value.trim()) {
          messages.value.push({ sender: 'user', text: userMessage.value });
          
          // Simulate bot response
          setTimeout(() => {
            messages.value.push({
              sender: 'bot',
              text: `You said: "${userMessage.value}"`
            });
          }, 1000);
          
          userMessage.value = '';
        }
      };
  
      return {
        userMessage,
        messages,
        sendMessage,
        selectedTab
      };
    }
  };
  </script>
  
  <style scoped>
  .chatbot-window {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    max-height: 500px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .close-button {
    align-self: flex-end;
    cursor: pointer;
    background: transparent;
    border: none;
    font-size: 16px;
  }
  
  .welcome-message {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .tab-content {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  .tab-panel {
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .messages {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .message {
    margin: 5px 0;
  }
  
  .message .bot {
    color: #073763;
    font-weight: bold;
  }
  
  .message .user {
    color: #333;
    text-align: right;
  }
  
  .input-area {
    display: flex;
    gap: 10px;
  }
  
  input {
    flex: 1;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  button {
    padding: 5px 10px;
    background-color: #073763;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .tabs {
  display: flex;
  justify-content: space-around;
  padding: 2px 0;
  border-top: 1px solid #ddd;
  margin-top: 5px;
}

.tabs button {
  flex: 1;
  padding: 4px 6px; /* Reduced padding */
  font-size: 12px; /* Smaller font size */
  border: none;
  cursor: pointer;
  background-color: #f1f1f1;
  font-weight: bold;
  color: #555;
  border-radius: 3px; /* Slightly smaller radius */
  margin: 0 3px; /* Reduced margin between buttons */
}

.tabs button.active {
  background-color: #073763;
  color: white;
}

  
  .tabs button.active {
    background-color: #073763;
    color: white;
  }
  </style>
  